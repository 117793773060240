import React from 'react';
import { Login } from '../components/Login/Login';

function login() {
    return (
        <div className='bg-gray-100'>
            <Login />
        </div>
    );
};

export default login;