import React from 'react';
import { NavBar } from '../components/Header/NavBar'
import { SlideShow } from '../components/HomePage/SlideShow';
import { Footer } from '../components/Footer/Footer';
import { Avaliacoes } from '../components/Avaliacoes/Avaliacoes';
import { Atividades } from '../components/Atividades/Atividades';
import { Restauracao } from '../components/Restauracao/Restauracao';
import {Quartos} from '../components/Quartos/Rooms';
import 'bootstrap/dist/css/bootstrap.min.css';



function Home() {
  return (
    <div className='bg-gray-100'>
      <NavBar />
      <SlideShow />
      <Atividades />
      <Avaliacoes />
      <Quartos/>
      <Restauracao />
      <Footer />
    </div>
  );
}

export default Home;
