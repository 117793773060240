import * as react from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";

export function Footer(props) {
    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>


            <section className=''>
                <MDBContainer className='text-center text-md-start mt-5'>
                    <MDBRow className='mt-3'>
                        <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4 font-Logo text-2xl ml-[-16px]'>
                                <MDBIcon color='secondary' icon='gem' className='me-3' />
                                Hoteis Portugal
                            </h6>
                            <p>
                            Bem-vindo ao Hotel Portugal, onde a beleza do mar encontra o 
                            conforto e a hospitalidade excepcionais. Nosso hotel oferece vistas panorâmicas do 
                            oceano que irão encantar os seus sentidos.
                            </p>
                        </MDBCol>

                        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Produtos</h6>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Quartos
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Atividades
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Restauração
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Ofertas
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Vouchers
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Links Úteis</h6>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Pedidos
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Help
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>Contactos</h6>
                            <p>
                                <MDBIcon color='secondary' icon='home' className='me-2' />
                                Rua das Bananas, Nº4, PT
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='envelope' className='me-2' />
                                Contacto@HpHoteisPortugal.pt
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='phone' className='me-2' /> +351 269449666
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='print' className='me-2' /> +351 269111397
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>

            <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                <span>© 2024 Copyright: </span>
                <a className='text-reset fw-bold' href='http://HpHoteisPortugal.pt/'>
                    <span>HpHoteisPortugal.pt</span>
                </a>
            </div>
        </MDBFooter>
    );
}