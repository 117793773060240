import React from 'react';
import Suite from "../../Images/HomePage/Carrossel/quarto1.png"
import SuiteLuadeMel from "../../Images/HomePage/Carrossel/quarto2.png"
import SuiteCasal from "../../Images/HomePage/Carrossel/quarto3.png"


function Rooms(props) {
    return (
        <><div className="flex-auto">
            <div className='mt-[37px] bg-white h-[334px] w-[full]'>
                <div className='w-[500px] h-[400px]'>
                    <img src={Suite} alt="" className='rounded-lg' />
                </div>
                <div className="font-Corpo font-semibold text-5xl px-8 py-3 ml-[500px] mt-[-390px]">
                    Suite
                </div>
                <div className='flex ml-[-100px] mt-[10px]'>
                    <span className="ml-[635px]">
                        Desde...
                    </span>
                    <span className=" ml-[225px] text-2xl font-VerMais font-semibold">
                        Descrição do quarto
                    </span>
                    <span className="mt-[50px] ml-[-530px]">
                        Lotação maxima: ... - ... M2
                    </span>
                    <span className="mt-[100px] ml-[-187px] ">
                        Disponibilidade...
                    </span>
                    <button className="w-[100px] h-[40px] ml-[-120px] mt-[175px] bg-red-500 text-white p-2 rounded-lg mb-6 hover:bg-red-700 hover:text-black">
                        Reservar
                    </button>
                </div>
            </div>
        </div>

            <div className="flex-auto">
                <div className='mt-[37px] bg-white h-[334px] w-[full] '>
                    <div className='w-[500px] h-[400px] ml-[858px]'>
                        <img src={SuiteLuadeMel} alt="" className='rounded-lg' />
                    </div>
                    <div className="font-Corpo font-semibold text-5xl px-8 py-3 ml-[25px] mt-[-390px]">
                        Suite
                    </div>
                    <div className='flex ml-[-575px] mt-[10px]'>
                        <span className="ml-[635px]">
                            Desde...
                        </span>
                        <span className=" ml-[225px] text-2xl font-VerMais font-semibold">
                            Descrição do quarto
                        </span>
                        <span className="mt-[50px] ml-[-530px]">
                            Lotação maxima: ... - ... M2
                        </span>
                        <span className="mt-[100px] ml-[-187px] ">
                            Disponibilidade...
                        </span>
                        <button className="w-[100px] h-[40px] ml-[-120px] mt-[175px] bg-red-500 text-white p-2 rounded-lg mb-6 hover:bg-red-700 hover:text-black">
                            Reservar
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex-auto">
                <div className='mt-[37px] bg-white h-[334px] w-[full]'>
                    <div className='w-[500px] h-[400px] '>
                        <img src={SuiteCasal} alt="" className='rounded-lg' />
                    </div>
                    <div className="font-Corpo font-semibold text-5xl px-8 py-3 ml-[500px] mt-[-390px]">
                        Suite
                    </div>
                    <div className='flex ml-[-100px] mt-[10px]'>
                        <span className="ml-[635px]">
                            Desde...
                        </span>
                        <span className=" ml-[225px] text-2xl font-VerMais font-semibold">
                            Descrição do quarto
                        </span>
                        <span className="mt-[50px] ml-[-530px]">
                            Lotação maxima: ... - ... M2
                        </span>
                        <span className="mt-[100px] ml-[-187px] ">
                            Disponibilidade...
                        </span>
                        <button className="w-[100px] h-[40px] ml-[-120px] mt-[175px] bg-red-500 text-white p-2 rounded-lg mb-6 hover:bg-red-700 hover:text-black">
                            Reservar
                        </button>
                    </div>
                </div>
            </div>



        </>


    );
}

export const Quartos = Rooms;

export default Rooms; 
