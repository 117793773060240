import React from "react";

export function AtividadesText(props) {

    return (
        <div>
            <h1 className="text-2xl font-semibold text-gray-600 mb-6">
                Descubra a Magia do Nosso Hotel: Experiências Memoráveis Aguardam por Si
            </h1>
            <p className="text-lg text-gray-700 mb-4">
                Bem-vindo ao nosso encantador hotel, onde cada momento se transforma em
                memórias inesquecíveis. Explore as atividades que temos para oferecer:
            </p>
            <ul className="text-sm">
                <li className="activity mb-2">
                    <span className="text-gray-600 font-bold">•</span><b> Baile de Máscaras:</b> Viva
                    uma noite de mistério e elegância, dançando ao som envolvente e envolto na
                    magia de máscaras deslumbrantes.
                </li>
                <li className="activity mb-2">
                    <span className="text-gray-600 font-bold">•</span><b> Festa na Praia: </b>
                    Deixe-se levar pela brisa marítima em nossa festa à beira-mar, com música
                    envolvente, iguarias deliciosas e uma atmosfera vibrante.
                </li>
                <li className="activity mb-2">
                    <span className="text-gray-600 font-bold">•</span><b> Passeio a Cavalo: </b>
                    Conecte-se com a natureza em um tranquilo passeio a cavalo, explorando as
                    paisagens pitorescas ao redor do hotel.
                </li>
                <li className="activity mb-2">
                    <span className="text-gray-600 font-bold">•</span><b> Passeio de Moto 4:</b> Para
                    os aventureiros, oferecemos emocionantes passeios por trilhas selvagens,
                    proporcionando uma experiência marcante.
                </li>
                <li className="activity mb-0">
                    <span className="text-gray-600 font-bold">•</span><b> Spa:</b> Relaxe e mime-se
                    em nosso luxuoso spa, desfrutando de massagens terapêuticas e tratamentos
                    faciais que restauram o equilíbrio do corpo e da mente.
                </li>
            </ul>
            <p className="text-lg text-gray-700">
                Junte-se a nós para descobrir a magia única que aguarda por si neste oásis
                de hospitalidade.
            </p>
        </div>
    );

}