import React from 'react';
import { Registo } from '../components/Registo/Registo';

function registo()  {
    return (
        <div className='bg-gray-100'>
            <Registo />
        </div>
    );
};

export default registo;