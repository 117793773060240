import * as react from "react";

export function NavBar(props) {
    return (
        <div className="flex gap-5 justify-between items-start px-14 py-5 w-full h-[120px] mt-[-40px] font-medium bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <div className="flex-auto self-stretch text-5xl text-gray-600 font-Logo mt-[5px] ml-[-30px]">
                Hoteis Portugal
            </div>
            <div className="flex gap-10 justify-between mt-3.5 text-2xl whitespace-nowrap text-zinc-700 max-md:flex-wrap max-md:max-w-full font-Corpo mr-[120px] mt-[16px]">
                <div className="grow"><a href="/Quartos" className="no-underline text-zinc-700">Quartos</a></div>
                <div className="flex-auto"><a href="" className="no-underline text-zinc-700">Restauração</a></div>
                <div className="flex-auto"><a href="" className="no-underline text-zinc-700">Atividades</a></div>
                <div className="grow"><a href="" className="no-underline text-zinc-700" >Ofertas</a></div>
            </div>
            <div className="justify-center px-2.5 py-2.5 mt-1.5 text-2xl whitespace-nowrap rounded-2xl bg-zinc-500 bg-opacity-70 text-slate-100 font-ItensNav">
                Reservas
            </div>
        </div>
    );
}