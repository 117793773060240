// import logo from './logo.svg';
// import './App.css';
// import './index.css'
// import {NavBar} from './components/Header/NavBar'
// import { SlideShow } from './components/HomePage/SlideShow';
// import {Footer} from './components/Footer/Footer'


// function App() {
//   return (
//     <div className='bg-gray-100'>
//        <NavBar/>
//        <SlideShow/>
//        <Footer/>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import HomePage from './pages/Home';
import Testes from './pages/Testes';
import Goncalo from './pages/goncalo';
import Login from './pages/Login';
import Registo from './pages/Registo';
import { Quartos } from './pages/Quartos';


// import AboutPage from './pages/About'; // Assuming you have an About page component

function App() {
  return (
    <Router>
      <Link to='/home' />
      <Link to='/testes' />
      <Link to='/goncalo' />
      <Link to='/login' />
      <Link to='/registo' />
      <Link to='/Quartos' />


      {/* <Link to='/about'>About</Link> */}

      <Routes>
        <Route path='/home' element={<HomePage />} />
        <Route path='/testes' element={<Testes />} />
        <Route path='/goncalo' element={<Goncalo />} />
        <Route path='/login' element={<Login />} />
        <Route path='/registo' element={<Registo />} />
        <Route path='/quartos' element={<Quartos />} />



        {/* <Route path='/about' element={<AboutPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;