import React from "react";
import { Carousel } from "react-bootstrap";

import BaileMascaras from '../../Images/HomePage/Atividades/BaileMascaras.png';
import FestaPraia from '../../Images/HomePage/Atividades/FestaPraia.jpeg';
import PasseioCavalo from '../../Images/HomePage/Atividades/PasseioCavalo.png';
import Spa from '../../Images/HomePage/Atividades/Spa.png';
import PasseioMota from '../../Images/HomePage/Atividades/PasseioMota.png'

const images = [BaileMascaras, FestaPraia, PasseioCavalo, Spa, PasseioMota];

export function SlideShowAtividades(props){
    return(
        <Carousel className="absolute d-block w-[685px] h-[417px] ml-[584px] top-7" >
            {images.map((image, index) =>(
                <Carousel.Item key={index}>
                    <img className="d-block rounded-md w-[685px] h-[417px] " src={image} alt={`Imagem ${index}`} />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}