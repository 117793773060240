import React from "react";
import { SlideShowAtividades } from "./SlideShowAtividades";
import { AtividadesText } from "./AtividadesText";

import FestaPraia from '../../Images/HomePage/Atividades/FestaPraia.jpeg';
import PasseioCavalo from '../../Images/HomePage/Atividades/PasseioCavalo.png';
import Spa from '../../Images/HomePage/Atividades/Spa.png';
import PasseioMota from '../../Images/HomePage/Atividades/PasseioMota.png'

export function Atividades(props) {
    return (
        <div className="flex-auto">
            <div className="font-Corpo font-semibold text-5xl px-8 py-3 mt-[20px]">
                Atividades
            </div>
            <div className="ml-[30px] mt-[0px] w-[1290px] h-[600px] bg-white rounded-xl"> {/* Card (retangulo branco onde fica o SlideShow)*/}
                <SlideShowAtividades />
                <div className="flex gap-3 w-[160px] mt-[50px] ml-[582px]">
                    <img className="rounded" src={FestaPraia} alt="Festa na Praia" />
                    <img className="rounded" src={PasseioCavalo} alt="Passeio de Cavalo" />
                    <img className="rounded" src={Spa} alt="Spa" />
                    <img className="rounded" src={PasseioMota} alt="Passeio de Mota" />
                </div>
                <div className="mt-[-550px] ml-[20px] h-[600px] w-[500px]">
                    <AtividadesText />
                </div>
            </div>
        </div>
    );
}

