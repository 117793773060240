import React from 'react';
import { Carousel, Card } from 'react-bootstrap';


export function Avaliacoes(props) {
  return (
    <div className="flex gap-3 " >
           
      <Carousel data-bs-theme="dark" className='flex gap-5 w-[1000px] h-[425px] ml-[200px]'>
        <Carousel.Item className='ml-[250px]'>
          <Card className="flex gap-3 w-[160px] mt-[50px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 1</Card.Title>
              <Card.Text>
                Texto da avaliaçao
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="flex gap-3 w-[160px] mt-[-288px] ml-[182px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 2</Card.Title>
              <Card.Text>
                Texto da avaliação
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="flex gap-3 w-[160px] mt-[-288px] ml-[364px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 3</Card.Title>
              <Card.Text>
                Texto da avaliação
              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
        <Carousel.Item className='ml-[250px]'>
          <Card className="flex gap-3 w-[160px] mt-[50px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 4</Card.Title>
              <Card.Text>
                Texto da avaliaçao
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="flex gap-3 w-[160px] mt-[-288px] ml-[182px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 5</Card.Title>
              <Card.Text>
                Texto da avaliação
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="flex gap-3 w-[160px] mt-[-288px] ml-[364px]">
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Card 6</Card.Title>
              <Card.Text>
                Texto da avaliação
              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

