import React from 'react';
import { Login } from '../components/Login/Login';
import { Registo } from '../components/Registo/Registo';

const goncalo = () => {
    return (
        <div className='bg-gray-100'>
            <Login />
            <Registo />
        </div>
    );
};

export default goncalo;