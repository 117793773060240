import React from 'react';

import Sushi from '../../Images/Restauracao/Sushi.png';
import Bar from '../../Images/Restauracao/Bartender.png';
import PequenoAlmoco from '../../Images/Restauracao/pequenoAlmoco.png';
import Buffet from '../../Images/Restauracao/Buffet.png';
import Taberna from '../../Images/Restauracao/Taberna.png';

export function Restauracao(props) {
    return (
        <div className="flex-auto">

            <div className="font-Corpo font-semibold text-5xl px-8 py-3 pl-10px mt-[20px] ">
                Restauração
            </div>

            <div className='flex mt-[20px] w-[390px] space-x-16 ml-[75px]'>
                <img src={PequenoAlmoco} alt="Restauracao" />
                <img src={Bar} alt="Restauracao" />
                <img src={Sushi} alt="Restauracao" />
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[935px] mt-[-40px] ">
                Sushi
            </div>
            <div className="mt-[70px]">
            </div>


            <div className="font-VerMais font-extrabold text-l text-white  ml-[1105px] mt-[-95px] ">
                <a href="/restauracao" className="no-underline text-white">Ver Detalhes 🠖 </a>
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[515px] mt-[-95px] ">
                Bar
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[685px] mt-[-95px] ">
                <a href="/restauracao" className="no-underline text-white">Ver Detalhes 🠖</a>
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[90px] mt-[-92px] ">
                Pequeno-Almoço
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[265px] mt-[-95px] ">
                <a href="/restauracao" className="no-underline text-white">Ver Detalhes 🠖</a>
            </div>
            <div className="mt-[70px]">
            </div>

            <div className='flex mt-[20px] w-[566px] space-x-16 ml-[75px]' >
                <img src={Buffet} alt="Restauracao" />
                <img src={Taberna} alt="Restauracao" />
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[100px] mt-[-40px] ">
                Buffet
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[1105px] mt-[-95px] ">
                <a href="/restauracao" className="no-underline text-white">Ver Detalhes 🠖</a>
            </div>
            <div className="mt-[500px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[730px] mt-[-523px] ">
                Taberna
            </div>
            <div className="mt-[70px]">
            </div>

            <div className="font-VerMais font-extrabold text-l text-white  ml-[475px] mt-[-96px] ">
                <a href="/restauracao" className="no-underline text-white">Ver Detalhes 🠖</a>
            </div>
            <div className="mt-[100px]">
            </div>
        </div>
    );
};

