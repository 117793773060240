import React from "react";

import Suite from '../../Images/HomePage/Quartos/Suite.png';
import Suite_Lua_Mel from '../../Images/HomePage/Quartos/Suite_Lua_Mel.png';
import Suite_Casal from '../../Images/HomePage/Quartos/Suite_Casal.png';
import Duplex from '../../Images/HomePage/Quartos/Duplex.png';
import Ver_Mais_Quartos from '../../Images/HomePage/Quartos/Ver_Mais_Quartos.png';


const precos = [
    "90€",
    "135€",
    "105€",
    "50€"
];

export function Quartos(props) {

    return (
        <div className="ml-[30px]">
            <div>
                <div className="font-Corpo font-semibold text-5xl px-8 py-3 mt-[20px] text-center">
                    Quartos & Suites
                </div>
                <div className="font-Corpo font-semibold text-base px-8 py-3 mt-[-30px] text-center text-41414D">
                    Os Nossos Melhores Quartos e Suites
                </div>
            </div>
            <div>
                <div className="flex mt-[20px] w-[390px] space-x-16">
                    <img src={Suite} alt="Quartos" />
                    <img src={Suite_Lua_Mel} alt="Quartos" />
                    <img src={Suite_Casal} alt="Quartos" />
                </div>
                <div className="flex text-white">
                    <p className=" mt-[-55px] ml-[15px]">
                        Suite
                    </p>
                    <span className=" mt-[-35px] ml-[-35px]">
                        90€ / Noite
                    </span>
                    <span className=" mt-[-35px] ml-[160px] ">
                        Ver Detalhes 🠖
                    </span>
                </div>
                <div className="flex text-white">
                    <p className=" mt-[-55px] ml-[470px]">
                        Suite
                    </p>
                    <span className=" mt-[-35px] ml-[-35px]">
                        90€ / Noite
                    </span>
                    <span className=" mt-[-35px] ml-[160px] ">
                        Ver Detalhes 🠖
                    </span>
                </div>
                <div className="flex text-white">
                    <p className=" mt-[-55px] ml-[925px]">
                        Suite
                    </p>
                    <span className=" mt-[-35px] ml-[-35px]">
                        90€ / Noite
                    </span>
                    <span className=" mt-[-35px] ml-[160px] ">
                        Ver Detalhes 🠖
                    </span>
                </div>


                <div>
                    <div className="flex mt-[25px] w-[390px] space-x-16">
                        <img src={Duplex} alt="Quartos" />
                        <img src={Ver_Mais_Quartos} alt="Mais Quartos" />
                    </div>
                    <div className="flex text-white">
                        <p className=" mt-[-55px] ml-[15px]">
                            Suite
                        </p>
                        <span className=" mt-[-35px] ml-[-35px]">
                            90€ / Noite
                        </span>
                        <span className=" mt-[-35px] ml-[160px] ">
                            Ver Detalhes 🠖
                        </span>
                    </div>
                    <div className="font-VerMais font-extrabold text-4xl ml-[490px] mt-[-150px]">
                        <a href="/quartos" className="no-underline text-white">Ver Mais Opções</a>
                    </div>
                </div>
                <div className="mt-[150px]">
                </div>
            </div>
        </div>
    );

}