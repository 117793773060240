import React from 'react';
import { NavBar } from '../components/Header/NavBar'
import { SlideShow } from '../components/HomePage/SlideShow';
import { Footer } from '../components/Footer/Footer';
import { Atividades } from '../components/Atividades/Atividades';
import { Quartos } from '../components/Quartos_Home/Quartos';
import { Restauracao } from '../components/Restauracao/Restauracao';





function Testes() {
  return (
    <div className='bg-gray-100'>
      <NavBar />
      <SlideShow />
      <Atividades />
      <Quartos />
      <Restauracao />

      <Footer />
    </div>
  );
}

export default Testes;
