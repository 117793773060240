import React from 'react';
import fundo from '../../Images/Registo/Registo.jpg';
import RegistoSvgComponent from '../Auxiliares/SVGLoginComponent';

export function Registo(props) {
    return (
        <div>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Document</title>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
                    {/* left side */}
                    <div className="w-full flex flex-col justify-center p-5 md:p-14">
                        <span className="mb-3 text-3xl font-bold">Bem-vindo</span>
                        <span className="font-light text-gray-400 mb-8">
                            Bem-vindo! Registe as suas credenciais
                        </span>
                        <div className="py-1"> {/* estava 4*/}
                            <span className="mb-2 text-md">Email</span>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
                                name="email"
                                id="email"
                            />
                        </div>
                        <div className="py-2">
                            <span className="mb-2 text-md">Nome</span>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
                                name="nome"
                                id="nome"
                            />
                        </div>
                        <div className="py-2">
                            <span className="mb-2 text-md">Password</span>
                            <input
                                type="password"
                                name="pass"
                                id="pass"
                                className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
                            />
                        </div>
                   
                        <button className="w-full bg-black text-white p-2 rounded-lg mb-6 hover:bg-white hover:text-black hover:border hover:border-gray-300">
                            Entrar
                        </button>
                        <button className="w-full border border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-black hover:text-white">
                            <RegistoSvgComponent className="w-6 h-6 inline mr-2" />
                            Registar com o Google
                        </button>

                        <div className="text-center text-gray-400">
                            Já tem uma conta?
                            <span className="font-bold text-black"> Clique aqui!</span>
                        </div>
                    </div>
                    {/* {/* right side * /} */}
                    <div className="w-full relative">
                        <img
                            src={fundo}
                            alt="img"
                            className="w-[615px] h-full hidden rounded-r-2xl md:block object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

