import React from 'react';
import { Carousel } from 'react-bootstrap';

import quarto1 from '../../Images/HomePage/Carrossel/quarto1.png';
import quarto2 from '../../Images/HomePage/Carrossel/quarto2.png';
import quarto3 from '../../Images/HomePage/Carrossel/quarto3.png';
import quarto4 from '../../Images/HomePage/Carrossel/quarto4.png';

const images = [quarto1, quarto2, quarto3, quarto4];
const textos = [
    "Não perca a oportunidade de ter a melhor experiência da sua vida.",
    "Seu descanso merecido está aqui.",
    "Férias nunca são demais e aqui você encontra um pedacinho do paraíso",
    "Que tal viver uma experiência de hospedagem única e luxuosa?"
];
const titulos = [
    "Suite: ",
    "Suite Lua de Mel: ",
    "Suite de Casal: ",
    "Duplex: "
];

export function SlideShow(props) {
    return (
        <Carousel>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image} style={{ maxHeight: '571px', objectFit: 'cover' }} alt={`Imagem ${index + 1}`} />
                    <Carousel.Caption style={{ color: "#c0c0c0" }}>
                        <h3>{titulos[index]}</h3>
                        <p>{textos[index]}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
