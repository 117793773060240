import { useState, React } from 'react';
import { useNavigate } from "react-router-dom";
import fundo from '../../Images/Login/fundo.jpg';
import LoginSvgComponent from '../Auxiliares/SVGLoginComponent';



export function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false)
    const navigate = useNavigate();

    const logIn = () => {
        fetch(`http://localhost:5000/users?email=${email}`)
            .then(res => res.json())
            .then((result) => {
                if (result.length == 0) {
                    window.alert("O e-mail não existe!");
                } else if (email === result[0].Email && password !== result[0].Password) {
                    window.alert("Password inválida!!");
                } else {
                    localStorage.setItem("user", JSON.stringify({ email, token: result.token }))
                    setLoggedIn(true)
                    setEmail(email)
                    navigate("/testes")
                }
            });
    }


    return (
        <div>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Document</title>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
                    {/* left side */}
                    <div className="flex flex-col justify-center p-5 md:p-14">
                        <span className="mb-3 text-3xl font-bold">Bem-vindo de volta</span>
                        <span className="font-light text-gray-400 mb-8">
                            Bem-vindo de volta! Insira as suas credenciais
                        </span>
                        <div className="py-1"> {/* estava 4*/}
                            <span className="mb-2 text-md">Email</span>
                            <input
                                type="text"
                                value={email}
                                className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
                                onChange={ev => setEmail(ev.target.value)}
                                name="email"
                                id="email"
                            />
                        </div>
                        <div className="py-3">
                            <span className="mb-2 text-md">Password</span>
                            <input
                                type="password"
                                value={password}
                                name="pass"
                                id="pass"
                                onChange={ev => setPassword(ev.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md placeholder:font-light placeholder:text-gray-500"
                            />
                        </div>
                        <div className="flex justify-between w-full py-4">
                            <div className="mr-24">
                                <input type="checkbox" name="ch" id="ch" className="mr-2" />
                                <span className="text-md">Mantenha-me conectado</span>
                            </div>
                            <span className="font-bold text-md">Esqueci-me da password</span>
                        </div>
                        <button className="w-full bg-black text-white p-2 rounded-lg mb-6 hover:bg-white hover:text-black hover:border hover:border-gray-300" onClick={logIn}>
                            Entrar
                        </button>
                        {/* <button className="w-full border border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-black hover:text-white">
                            <LoginSvgComponent className="w-6 h-6 inline mr-2" />
                            Entrar com o Google
                        </button> */}

                        <div className="text-center text-gray-400">
                            Não tem uma conta?
                            <span className="font-bold text-black"> Inscreva-se gratuitamente</span>
                        </div>
                    </div>
                    {/* {/* right side * /} */}
                    <div className="relative">
                        <img
                            src={fundo}
                            alt="img"
                            className="w-[400px] h-full hidden rounded-r-2xl md:block object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};