import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rooms from '../components/Quartos/Rooms'; // Remova as chaves aqui
import { NavBar } from '../components/Header/NavBar';
import ReservasCliente from '../components/Testes/TestarMostrarRes';

export function Quartos() {
  return (
    <div className='bg-gray-100'>
      <NavBar />

      <Rooms/>

      

    </div>
  );
}


